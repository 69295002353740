.content__3fpl, .content__3fpl-, .payments__HHA6p .content__3fpl {
    background: transparent !important;
    border-radius: inherit !important;
    box-shadow: unset !important;
    letter-spacing: unset !important;
    max-width: unset !important;
    min-width: unset !important;
    padding: unset !important;
    width: 100%;
}
.capitalize{
     display: inline-block;
}

.capitalize:first-letter{
    text-transform: uppercase;
}

.main-phone-number .vs__search {
  padding: 0;
}

.main-phone-number .v-select, .v-select:not(.vs--open) > * {
  cursor: pointer !important;
}

.main-phone-number .v-select.vs--open > .vs__selected {
  top: 12px;
  opacity: 0.1;
}

.main-phone-number .v-select.vs--open > .vs__search {
  padding-left: 24px;
}

.main-phone-number .v-select:not(.vs--open) > .vs__search:-moz-placeholder {
  visibility: hidden;
  color: #fff;
}

.main-phone-number .v-select:not(.vs--open) > .vs__search::-moz-placeholder {
  visibility: hidden;
  color: #fff;
}

.main-phone-number .v-select:not(.vs--open) > .vs__search::placeholder {
  visibility: hidden;
  color: #fff;
}
.main-phone-number .v-select:not(.vs--open) > .vs__search::-webkit-input-placeholder {
  visibility: hidden;
  color: #fff;
}
.main-phone-number .v-select:not(.vs--open) > .vs__search::-ms-input-placeholder {
  visibility: hidden;
  color: #fff;
}

.main-phone-number .v-select {
  height: 60px;
  cursor: pointer !important;
}

.main-phone-number .v-select > .vs__actions {
  display: none;
}

.main-phone-number .v-select > .vs__search::placeholder,
.main-phone-number .v-select > .vs__dropdown-toggle,
.main-phone-number .v-select > .vs__dropdown-menu {
  width: 600px;
  color: #757678;
  background: #fff;
  border: none transparent;
  box-shadow: none;
  height: 60px;
}

.main-phone-number .mobileDescriptor {
  margin-left: 0;
}

.main-phone-number .v-select > .vs__selected-options {
  flex-basis: initial;
  flex-grow: initial;
}

.main-phone-number .vs__selected img{
    width: 24px;
    margin: auto auto auto 18px;
}

.vs__dropdown-menu {
    z-index: 9999 !important;
}

.vs__dropdown-option .vs__dropdown-option--highlight{
    color: #fff !important;
}

@media all and (min-width: 768px) {
  .main-phone-number .v-select > .vs__dropdown-toggle {
    width: 500px;
    color: #757678;
    background: #fff;
    border: none transparent;
    box-shadow: none;
    height: 60px;
  }

  .main-phone-number .v-select {
    width: 150px;
  }
}

@media all and (max-width: 768px) {
    .main-phone-number .phone-number-text {
        display: block;
    }

    .main-phone-number .v-select > .vs__dropdown-toggle {
        width: 100%;
        color: #757678;
        background: #fff;
        border: none transparent;
        box-shadow: none;
        height: 60px;
    }
}

.loader-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFFCC;
    z-index: 10;

}
.loader {
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid #36BAE1;
    border-right: 1.1em solid #36BAE1;
    border-bottom: 1.1em solid #36BAE1;
    border-left: 1.1em solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
    z-index: 10;
}
.loader,
.loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}
@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media all and (max-width: 768px) {
    .vs__dropdown-option {
        font-size: 12px;
        color: red !important;
    }

    .vs__dropdown-menu {
        min-width: 260px !important;
        color: red;
    }

    .vs__open-indicator{
        display: none;
    }
    .vs--open .vs__selected{
        display: none;
    }
}
